////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(237, 237, 237, 1);
}
.bg-secondaryColor {
  background-color: rgba(228, 228, 228, 1);
}
.bg-thirdColor {
  background-color: rgba(228, 228, 228, 1);
}
.bg-fourthColor {
  background-color: rgba(174, 66, 127, 1);
}
.bg-fifthColor {
  background-color: rgba(205, 159, 188, 1);
}
.bg-sixthColor {
  background-color: rgba(237, 237, 237, 1);
}
.bg-seventhColor {
  background-color: rgb(0, 0, 0);
}
